import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { Header, Footer, Kembali } from "../index";
import { Divider, Paper, Typography } from "@mui/material";
import { getWagonClass, priceFormatter } from "../../common/Utils";
import uiConfig from "../../configs/uiConfig.json"

const SelectStationPriceCard = () => {
  const { stationpricecardPage } = uiConfig;
  const header = "Cari Jadwal Kereta";
  const navigate = useNavigate();
  const [trainScheduleData, setTrainScheduleData] = useState([]);

  const handleTiciketSelect = (i) => {
    let KAI = JSON.parse(localStorage.getItem("KAI"));

    KAI.ticketDetails = trainScheduleData[i];
    KAI.noka = trainScheduleData[i].noka;
    localStorage.setItem("KAI", JSON.stringify(KAI));
    navigate("/inputdata");
  };

  useEffect(() => {
    async function getStationPriceCallback() {
      let KAI = await JSON.parse(localStorage.getItem("KAI"));
      if (!(KAI?.stationDetails && KAI?.trainScheduleResponse)) navigate("/");
      else {
        // remove any extra keys if available.
        KAI = {
          stationDetails: KAI.stationDetails,
          trainScheduleResponse: KAI.trainScheduleResponse,
        };
        localStorage.setItem("KAI", JSON.stringify(KAI));
        setTrainScheduleData(KAI.trainScheduleResponse.data);
      }
    }
    getStationPriceCallback()
  }, [navigate]);

  const getTripDuration = (departdatetime, arrivaldatetime) => {
    const start = moment(departdatetime, "YYYY-MM-DD HH:mm");
    const end = moment(arrivaldatetime, "YYYY-MM-DD HH:mm");
    const duration = moment.duration(end.diff(start));

    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours}j ${minutes}m`;
  };

  const isTiciketAvailable = (departdatetime, availability) => {
    const KAI = JSON.parse(localStorage.getItem("KAI"));
    const adultPassengers = KAI.stationDetails.adultPassengers;

    if (availability === 0 || availability < adultPassengers) return false;

    const { isCardValidTimeInHours } = stationpricecardPage;
    return (
      moment(departdatetime, "YYYY-MM-DD HH:mm").diff(moment.tz("Asia/Jakarta").format("YYYY-MM-DD HH:mm"), "hours") >= isCardValidTimeInHours // NOW SET TO 1 hour previously was 3 hours
    );
  };

  const seatAvalibilityStatus = (availability) => {
    if (availability === 0) return <span>Kursi habis</span>;
    if (availability > 50) return <span>Tersedia</span>;
    else return <span> {availability} Kursi Tersedia</span>;
  };

  return (
    <div style={{ height: trainScheduleData.length > 3 ? "" : "100vh" }}>
      <Header header={header} back={"/"} step={"2"} />
      <div
        style={{
          padding: "16px",
          borderBottom: "1px solid #DDD",
          height: trainScheduleData.length > 3 ? "" : `calc(100vh - 151px)`,
        }}
      >
        {" "}
        {/* 151px => height(Header) + height(Footer) + 16px(Top & Bottom Padding of this div) */}
        {trainScheduleData.map(
          (
            {
              trainname,
              departuretime,
              arrivaltime,
              departdatetime,
              arrivaldatetime,
              stasiunorgcode,
              stasiundestcode,
              wagonclasscode,
              subclass,
              fares,
              departdate,
              arrivaldate,
              noka,
              availability,
            },
            i
          ) => {
            const tripDuration = getTripDuration(
              departdatetime,
              arrivaldatetime
            );
            const wagonClass = getWagonClass(wagonclasscode, subclass);
            const price = priceFormatter(fares[0].amount);
            const isTicketValid = isTiciketAvailable(
              departdatetime,
              availability
            );
            return (
              <Paper
                key={i}
                id={'ticketInfo-'+i}
                onClick={() => {
                  if (isTicketValid) handleTiciketSelect(i);
                }}
                style={{
                  padding: "16px",
                  borderRadius: "8px",
                  background: isTicketValid ? "" : "#F2F2F2",
                  boxShadow: "0px 2px 12px 2px rgba(87, 151, 169, 0.12)",
                  marginBottom: "16px",
                  cursor: isTicketValid ? "pointer" : "not-allowed",
                }}
              >
                {/* Train name Class--------------Price */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Typography
                      id="trainName"
                      children={`${trainname} (${noka})`}
                      style={{ color: isTicketValid ? "#333" : "#CCC" }}
                      className={"stationCardName"}
                    />
                    <Typography
                      id="trainClass"
                      children={wagonClass}
                      style={{ color: isTicketValid ? "#888" : "#CCC" }}
                      className={"stationCardClass"}
                    />
                  </div>
                  <div>
                    <Typography
                      id="trainPriceCard"
                      style={{
                        color: isTicketValid ? "#00529C" : "#CCC",
                        whiteSpace: "pre",
                      }}
                      className={"stationCardPrice"}
                    >
                      <span>
                        Rp {price}
                        <span style={{ fontSize: 10 }}>/orang</span>
                      </span>
                    </Typography>
                  </div>
                </div>

                {/* TIME */}
                <div className={"displayFlex"} style={{ marginTop: "16px" }}>
                  <div style={{ display: "flex", marginTop: "16px" }}>
                    <div>
                      <Typography
                        id="sourceStationCode"
                        children={stasiunorgcode}
                        style={{ color: isTicketValid ? "#849FBD" : "#CCC" }}
                        className={"stationTimeLabel"}
                      />
                      <Typography
                        id="sourceStationTime"
                        children={
                          departuretime.substring(0, 2) +
                          ":" +
                          departuretime.substring(2, 4)
                        }
                        style={{ color: isTicketValid ? "#1078CA" : "#CCC" }}
                        className={"stationTime"}
                      />
                    </div>
                    {/* TOTAL JOURNEY DURATION */}
                    <div className={"displayFlex"}>
                      <Divider style={{ width: "5vw", margin: "0 10px" }} />
                      <Typography
                        id="journeyTime"
                        children={tripDuration}
                        style={{
                          fontSize: 9,
                          fontWeight: 500,
                          color: isTicketValid ? "#888" : "#CCC",
                        }}
                      />
                      <Divider style={{ width: "5vw", margin: "0 10px" }} />
                    </div>
                    <div>
                      <Typography
                        id="destinationStationCode"
                        children={stasiundestcode}
                        style={{ color: isTicketValid ? "#849FBD" : "#CCC" }}
                        className={"stationTimeLabel"}
                      />
                      <Typography
                        id="destinationStationTime"
                        children={
                          arrivaltime.substring(0, 2) +
                          ":" +
                          arrivaltime.substring(2, 4)
                        }
                        style={{ color: isTicketValid ? "#1078CA" : "#CCC" }}
                        className={"stationTime"}
                      />
                      <Typography
                        id="exceedsOneDay"
                        children={"+1 hari"}
                        style={{
                          color: isTicketValid ? "#849FBD" : "#CCC",
                          display: departdate !== arrivaldate ? "" : "none",
                        }}
                        className={"stationTimeLabel"}
                      />
                    </div>
                  </div>
                  <div
                    style={{ color: !isTicketValid && "#CCC" }}
                    className={"remainingTicikets"}
                  >
                    {seatAvalibilityStatus(availability)}
                  </div>
                </div>
              </Paper>
            );
          }
        )}
        {/* BACK BUTTON */}
        <Kembali back={"/"} />
      </div>
      <Footer />
    </div>
  );
};

export default SelectStationPriceCard;
