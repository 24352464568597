import React, { useEffect, useState } from "react";
import "../Styles/InputData.css";
import "../Styles/Ringkasan.css";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TrainIcon from "@mui/icons-material/Train";
import { Header, Footer, SubmitedPassengerInfo, Loader } from "../index";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getWagonClass, priceFormatter } from "../../common/Utils";
import { closeEnrollment } from "../../api/ApiUtils";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";

const baseURL = process.env.REACT_APP_BASEURL;

const VerifiedInputData = () => {
  const navigate = useNavigate();
  const [stationTiciketCard, setStationTiciketCard] = useState({});
  const [contactDetails, setContactDetails] = useState({});
  const [passengerDetails, setPassengerDetails] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let KAI = JSON.parse(localStorage.getItem("KAI"));
    if (!KAI?.ticketBookingResponse) navigate("/inputdata");
    else {
      const { stationDetails, ticketDetails, customerContactDetails } = KAI;
      let stationTiciketCard = {
        tripdate: ticketDetails.tripdate,
        departdate: ticketDetails.departdate,
        originStation: stationDetails.sourceStation.stationname,
        destinationStation: stationDetails.destnationStation.stationname,

        departureTime: ticketDetails.departuretime,
        arrivalTime: ticketDetails.arrivaltime,

        trainName: ticketDetails.trainname,
        trainNumber: KAI.noka,
        wagonClass: ticketDetails.wagonclasscode,
        subClass: ticketDetails.subclass,

        adultPassengers: stationDetails.adultPassengers,
        childPassengers: stationDetails.childPassengers,
        totalPassengers:
          parseInt(stationDetails.adultPassengers) +
          parseInt(stationDetails.childPassengers),
        totalAmount:
          parseInt(stationDetails.adultPassengers) *
          parseInt(ticketDetails.fares[0].amount),
      };
      let verifiedPassenger = {
        paxes: KAI.ticketBookingResponse.data.paxes,
        destinationid: ticketDetails.destinationid,
        originId: ticketDetails.originid,
        subClass: KAI.ticketBookingResponse.data.subclass,
        wagonClassId: KAI.ticketBookingResponse.data.wagonclassid,
        tripId: KAI.ticketBookingResponse.data.tripid,
        paymentcode: KAI.ticketBookingResponse.data.paymentcode,
      };
      setStationTiciketCard(stationTiciketCard);
      setContactDetails(customerContactDetails);
      setPassengerDetails(verifiedPassenger);
    }
  }, [navigate]);

  const handleConfirmBooking = () => {
    setLoading(true);

    let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));

    const BASE_URL = baseURL;
    let URL = BASE_URL + "/kai/train/ticket/confirmation";

    const body = {
      enrollmentId: enrollmentId,
      paymentCode: passengerDetails.paymentcode,
    };

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Clientid: "Kai-Enrollment",
        // "Authorization": "Bearer " + token
      },
    };

    axios
      .post(URL, body, headers)
      .then((res) => {
        setLoading(false);
        if (res.data.success) navigate("/redirect");
        else {
          showErrorPopUp({ showPopUp: true, message: res.data.message });
          setOpenConfirm(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error)
      });
  };

  const handlePopUpClose = () => {
    showErrorPopUp({ ...errorPopUp, showPopUp: false });
  };
  const handleCloseEnrollment = async () => {
    setOpenCancel(false);
    const response = await closeEnrollment(navigate, "4");
    if (!response.success)
      showErrorPopUp({
        ...errorPopUp,
        showPopUp: true,
        message: response.message,
      });
  };
  const header = "Ringkasan";
  return (
    <div id={"VerifiedInputData"}>
      <Loader loading={loading} />
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <Header header={header} back={"/inputdata"} step={"4"} />
      <div id={"InputDataForm"} style={{ padding: "16px" }}>
        <StationTicketCard stationTiciketCard={stationTiciketCard} />

        {/* VERIFIED CONTACT DETAILS */}
        <div style={{ marginTop: "24px" }}>
          <Typography
            children={"Data Pemesan (Untuk E - Ticket)"}
            className={"inputSectionLabels"}
          />
        </div>
        <Paper
          className={"dropShadow"}
          style={{
            padding: "12px",
            marginTop: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Typography
              id="name"
              className={`summaryFilled-primaryText filledContact-primaryText`}
              children={contactDetails.name}
            />
            <Typography
              id="email"
              className={`summaryFilled-secondaryText filledContact-secondaryText`}
              children={contactDetails.email}
            />
            <Typography
              id="contact"
              className={`summaryFilled-secondaryText filledContact-secondaryText`}
              children={`+${contactDetails.phoneCode} ${contactDetails.phoneNo}`}
            />
          </div>
          <CheckCircleRoundedIcon style={{ color: "#1078CA" }} />
        </Paper>

        <SubmitedPassengerInfo passengerInfoList={passengerDetails} />

        <PricingDetails stationTiciketCard={stationTiciketCard} />

        <Button
          id={"Lanjutkan"}
          fullWidth
          children={"Lanjutkan"}
          className={`themeButton themeButtonContained`}
          variant={"contained"}
          onClick={() => setOpenConfirm(true)}
        />
        <Button
          id={"Batalkan"}
          fullWidth
          children={"Batalkan"}
          variant={"contained"}
          className={`themeButton themeButtonWhite`}
          onClick={() => setOpenCancel(true)}
          style={{ marginTop: 10 }}
        />
      </div>

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        maxWidth={"sm"}
        PaperProps={{ style: { borderRadius: 8 } }}
      >
        <div style={{ padding: "16px" }}>
          <Typography
            children={"Konfirmasi"}
            align={"center"}
            style={{
              color: "#00529C",
              fontSize: 20,
              fontWeight: 700,
              marginBottom: 16,
            }}
          />
          {/* MESSAGE */}
          <Typography
            style={{ textAlign: "center", marginBottom: 16 }}
            children={
              "Apakah semua data yang dimasukkan sudah benar? Anda tidak dapat mengubah data setelah ini"
            }
          />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Button
                className={`themeButton themeButtonOutline`}
                variant={"outlined"}
                fullWidth
                onClick={() => setOpenConfirm(false)}
              >
                Batalkan
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={`themeButton themeButtonContained`}
                variant={"contained"}
                onClick={() => handleConfirmBooking()}
                fullWidth
              >
                Ya, Benar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={openCancel}
        onClose={() => setOpenCancel(false)}
        maxWidth={"sm"}
      >
        <div style={{ padding: "16px" }}>
          <Typography
            children={"Konfirmasi"}
            align={"center"}
            style={{
              color: "#00529C",
              fontSize: 20,
              fontWeight: 700,
              marginBottom: 16,
            }}
          />
          {/* MESSAGE */}
          <Typography
            style={{ textAlign: "center", marginBottom: 16 }}
            children={"Apakah Anda yakin ingin membatalkan pesanan ?"}
          />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Button
                className={`themeButton themeButtonOutline`}
                variant={"outlined"}
                fullWidth
                onClick={() => setOpenCancel(false)}
              >
                Batalkan
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={`themeButton themeButtonContained`}
                variant={"contained"}
                onClick={() => handleCloseEnrollment()}
                fullWidth
              >
                Ya, Benar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
      <Footer />
    </div>
  );
};

export default VerifiedInputData;

const StationTicketCard = ({ stationTiciketCard }) => (
  <Paper
    id={"selectedStationTicketCard"}
    className={"paperCards"}
    style={{ padding: 0 }}
  >
    {/* HEADER ICON AND TEXT */}
    <div
      id={"selectedStationTicketCard-date"}
      className={"selectedTicketCard-header"}
    >
      <TrainIcon className={"selectedTicketCard-headerIcon"} />
      <Typography
        id={"selectedStationTicketCard-date-value"}
        className={"selectedTicketCard-headerText"}
        children={moment(stationTiciketCard.departdate, "YYYY-MM-DD").format(
          "ddd, DD MMM YYYY"
        )}
      />
    </div>

    <div style={{ margin: "8px 0", padding: "0 16px" }}>
      {/* STATION NAME */}
      <div
        id={"selectedStationTicketCard-name"}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Typography
          id={"selectedStationTicketCard-name-start"}
          className={"selectedTicketCard-station"}
          children={stationTiciketCard.originStation}
        />
        <ArrowForwardIcon
          fontSize="18"
          style={{ margin: "0 5px" }}
          className={"iconArrowInSelectInput"}
        />
        <Typography
          id={"selectedStationTicketCard-name-end"}
          className={"selectedTicketCard-station"}
          children={stationTiciketCard.destinationStation}
        />
      </div>
      {/* TIME */}
      <div
        id={"selectedStationTicketCard-time"}
        style={{ display: "flex", alignItems: "center", marginTop: 4 }}
      >
        <Typography
          id={"selectedStationTicketCard-time-start"}
          className={"selectedTicketCard-time"}
          children={moment(stationTiciketCard.departureTime, "HHm").format(
            "HH:mm"
          )}
        />
        <Divider
          style={{ width: "1vw", margin: "0 5px", background: "#333" }}
        />
        <Typography
          id={"selectedStationTicketCard-time-end"}
          className={"selectedTicketCard-time"}
          children={moment(stationTiciketCard.arrivalTime, "HHm").format(
            "HH:mm"
          )}
        />
      </div>
    </div>
    {/* TRAIN CLASS */}
    <div
      id={"selectedStationTicketCard-class"}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0 16px 16px 16px",
      }}
    >
      <Typography
        id={"selectedStationTicketCard-class-start"}
        className={"selectedTicketCard-trainClass"}
        children={
          stationTiciketCard.trainName +
          " (" +
          stationTiciketCard.trainNumber +
          ")"
        }
      />
      <Divider style={{ width: "1vw", margin: "0 5px", background: "#333" }} />
      <Typography
        id={"selectedStationTicketCard-class-end"}
        className={"selectedTicketCard-trainClass"}
        children={getWagonClass(
          stationTiciketCard.wagonClass,
          stationTiciketCard.subClass
        )}
      />
    </div>
  </Paper>
);

const PricingDetails = ({ stationTiciketCard }) => {
  const KAI = JSON.parse(localStorage.getItem("KAI"));

  let { adminfee, netamount } = KAI.ticketBookingResponse.data;
  const totalamount = priceFormatter(adminfee + netamount || 0);
  adminfee = priceFormatter(adminfee || 0);
  netamount = priceFormatter(netamount || 0);
  const [open, setOpen] = useState(false);

  return (
    <div style={{ margin: "24px 0" }}>
      <Typography children={"Rincian Harga"} className={"inputSectionLabels"} />
      <div style={{ marginTop: "16px" }}>
        <Accordion
          id={"RincianHarga-dropdown"}
          className={"dropShadow"}
          style={{ padding: "4px 16px" }}
          onChange={() => setOpen(!open)}
        >
          <AccordionSummary style={{ padding: 0 }}>
            <div style={{ width: "100%" }} className={"displayFlex"}>
              <div className={"displayFlex"}>
                {open ? (
                  <KeyboardArrowUpRoundedIcon
                    style={{ marginRight: "12px" }}
                    className={"iconArrowInSelectInput"}
                  />
                ) : (
                  <KeyboardArrowDownRoundedIcon
                    style={{ marginRight: "12px" }}
                    className={"iconArrowInSelectInput"}
                  />
                )}
                <Typography
                  id={"Harga-Yang-Anda-Bayar"}
                  children={"Harga yang Anda bayar"}
                  className={"priceCard-header"}
                />
              </div>
              <Typography
                id={"Harga-Yang-Anda-Bayar-value"}
                children={`Rp ${totalamount}`}
                className={"priceCard-headerPrice"}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails
            style={{ padding: "16px 0", borderTop: "2px dotted #DDD" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} className={"displayFlex"}>
                <Typography
                  id="Argo-Dewasa"
                  children={`Argo (Dewasa) x ${stationTiciketCard.adultPassengers}`}
                  className={"priceCard-detailText"}
                />
                <Typography
                  id="Argo-Rp"
                  children={`Rp ${netamount}`}
                  className={"priceCard-detailPrice"}
                />
              </Grid>
              <Grid item xs={12} className={"displayFlex"}>
                <Typography
                  id="Argo-Bayi"
                  children={`Argo (Bayi) x ${stationTiciketCard.childPassengers}`}
                  className={"priceCard-detailText"}
                />
                <Typography
                  id="GRATIS"
                  children={"GRATIS"}
                  className={"priceCard-detailPrice"}
                  style={{ color: "#0AB970" }}
                />
              </Grid>
              <Grid item xs={12} className={"displayFlex"}>
                <Typography
                  id="Argo-Bayi"
                  children={`Convenience Fee`}
                  className={"priceCard-detailText"}
                />
                <Typography
                  id="GRATIS"
                  children={`Rp ${adminfee}`}
                  className={"priceCard-detailPrice"}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
