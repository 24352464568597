import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Kembali = ({ back }) => {
  const navigation = useNavigate();
  return (
    <Button
      id={'returnButon'}
      className={"themeButton themeButtonWhite"}
      children={"Kembali"}
      variant={"filled"}
      fullWidth
      style={{ margin: "10px 0" }}
      onClick={() => navigation(back)}
    />
  );
};

export default Kembali;
