export const priceFormatter = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const getWagonClass = (code, subclass) => {
  switch (code) {
    case "EKS":
      return `Eksekutif (${subclass})`;
    case "EKO":
      return `Ekonomi (${subclass})`;
    case "BIS":
      return `Bussines (${subclass})`;
    case "PRE":
      return `Premium (${subclass})`;
    case "LUX":
      return `LUXURY (${subclass})`;
    case "PRI":
      return `Priority (${subclass})`;
    default:
      return `${code} (${subclass})`;
  }
};

export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};
export const getEnrollmentId = () => {
  if (isEmpty(JSON.parse(localStorage.getItem("enrollmentId")))) {
    if (!isEmpty(window.location.search.split("=")[1])) {
      localStorage.setItem(
        "enrollmentId",
        JSON.stringify(window.location.search.split("=")[1])
      );
    }
  }
  return JSON.parse(localStorage.getItem("enrollmentId"));
};
