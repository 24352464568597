import React, { useState } from "react";
import { Typography } from "@mui/material";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";

const Header = ({ header }) => {
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const handlePopUpClose = () => {
    showErrorPopUp({ showPopUp: false });
  };

  return (
    <div id="header" className="header">
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <Typography id="headerText" className="headerText" children={header} />
    </div>
  );
};

export default Header;
