/* eslint-disable */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { id as indonesian } from "date-fns/locale"; // this is localization for Indonesian Language......
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  IconButton,
  Paper,
  Radio,
  Slide,
  Typography,
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TextView } from "../../common/Components";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

const PassengerInfo = ({ ticiketInfo, submitError, disableFirstName }) => {
  const { adultPassengers, childPassengers, totalPassengers, departdate } =
    ticiketInfo;
  const classes = useStyle();
  const [openUserID, setOpenUserID] = useState(false);
  const [radioButton, setRadioButton] = useState("KTP"); // default button set to KTP
  const [userID, submitUserID] = useState(""); // set user id on click of submit button in Slider
  const [userIndex, setUserIndex] = useState(""); // gets the user index from list array on click on Jenis ID
  const [list, setList] = useState([]);

  const handlePassengerList = () => {
    // console.log(totalPassengers)
    if (totalPassengers > 0) {
      let temp = [];
      let j = adultPassengers,
        k = childPassengers;

      let KAI = JSON.parse(localStorage.getItem("KAI"));
      if (KAI?.passengerList?.length) {
        temp = [...KAI.passengerList];
        for (let i = 0; i < temp.length; i++) {
          temp[i].expand = false;
        }
        KAI.passengerList = temp;
        localStorage.setItem("KAI", JSON.stringify(KAI));
      } else {
        for (let i = 0; i < totalPassengers; ) {
          if (j > 0) {
            temp[i] = {
              passenger: "adult",
              passengerID: "KTP",
              name: "",
              numberID: "",
              id: i,
              expand: false,
            };
            j--;
            i++;
          }
          if (j == 0 && k > 0) {
            temp[i] = {
              passenger: "child",
              passengerID: "KTP",
              name: "",
              numberID: "",
              id: i,
              expand: false,
            };
            k--;
            i++;
          }
        }
        KAI.passengerList = [...temp];
        localStorage.setItem("KAI", JSON.stringify(KAI));
      }
      setList(temp);
    }
  };
  useEffect(() => {
    handlePassengerList();
  }, [adultPassengers, childPassengers, totalPassengers, disableFirstName]);

  const [passengerName, setPassengerName] = useState("");
  const [passengerNumberID, setPassengerNumberID] = useState("");
  const [childBirthDate, setChildBirthDate] = useState("");
  const [error, setError] = useState(false);
  const handleAccordion = (i) => {
    setUserIndex(i);
    const p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));

    p.then((res) => {
      let KAI = res;
      let list = [...KAI.passengerList];

      for (let index = 0; index < list.length; index++) {
        if (index == i) {
          list[index].expand = !list[index].expand;
          setPassengerName(list[i].name);
          setPassengerNumberID(list[i].numberID);
          setRadioButton(list[i].passengerID);
          if (list[i].passenger !== "adult") {
            setChildBirthDate(
              list[i]?.childBirthDate ? list[i]?.childBirthDate : ""
            );
          }
        } else list[index].expand = false;
      }
      setError(false);
      KAI.passengerList = [...list];
      localStorage.setItem("KAI", JSON.stringify(KAI));
      setList(list);
    });
  };

  // after clicking Submit button.
  const handleSubmitPassengerInfo = (passenger) => {
    let expand;
    let ageLimit = moment(departdate, "YYYY-MM-DD").diff(
      moment(childBirthDate).subtract(1, "days"),
      "years"
    );

    if (
      passengerName != "" &&
      passengerNumberID != "" &&
      ((radioButton == "KTP" &&
        passengerNumberID > 0 &&
        passengerNumberID.length == 16) ||
        (radioButton == "Paspor" && passengerNumberID.length == 8)) &&
      radioButton != "" &&
      (passenger == "adult" || (passenger == "child" && ageLimit < 3))
    ) {
      setError(false);
      expand = false;
    } else {
      setError(true);
      expand = true;
    }

    const p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));

    p.then((res) => {
      let KAI = res;
      let list = [...KAI.passengerList];
      list[userIndex].expand = expand;
      list[userIndex].name = passengerName;
      list[userIndex].numberID = passengerNumberID;
      list[userIndex].childBirthDate = childBirthDate;
      setList(list);
      KAI.passengerList = [...list];
      localStorage.setItem("KAI", JSON.stringify(KAI));
    });
  };

  useEffect(() => {
    if (submitError) {
      const p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
      p.then((res) => {
        let KAI = res;

        if (KAI?.passengerList?.length) {
          let passengerList = KAI.passengerList;

          for (let i = 0; i < passengerList.length; i++) {
            if (
              passengerList[i].name == "" ||
              passengerList[i].passengerID == "" ||
              passengerList[i].numberID == "" ||
              (passengerList[i].passengerID == "KTP" &&
                passengerList[i].numberID.length != 16) ||
              (passengerList[i].passengerID == "Paspor" &&
                passengerList[i].numberID != 8) ||
              (passengerList[i].passenger == "child" &&
                (passengerList[i].childBirthDate == "" ||
                  moment(departdate, "YYYY-MM-DD").diff(
                    moment(passengerList[i].childBirthDate).subtract(1, "days"),
                    "years"
                  ) >= 3))
            ) {
              passengerList[i].expand = true;
              setError(true);
              setPassengerName(passengerList[i].name);
              setPassengerNumberID(passengerList[i].numberID);
              setUserIndex(i);
              break;
            } else passengerList[i].expand = false;
          }
          setList(passengerList);
          KAI.passengerList = [...passengerList];
          localStorage.setItem("KAI", JSON.stringify(KAI));
        }
      });
    }
  }, [submitError]);

  useEffect(() => {
    if (userID && list.length) {
      const p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
      p.then((res) => {
        let KAI = res;
        KAI.passengerList[userIndex].passengerID = radioButton;
        KAI.passengerList[userIndex].numberID = "";
        setList(KAI.passengerList);
        localStorage.setItem("KAI", JSON.stringify(KAI));

        // we use common radio component for selecting ID type so keeping it empty after setting it
        // so we dont see same ID for other USERS
        submitUserID("");
        // console.log("changed userID");
        // clear the ID number on change of ID type
        setPassengerNumberID("");
      });
    }
  }, [userID]);

  return (
    <div id="slidercomponent">
      <div style={{ marginTop: "24px" }}>
        <Typography
          children={"Data Penumpang"}
          className={"inputSectionLabels"}
        />
      </div>
      {/* passenger => (adult/child) | passengerID => (KTP/Paspor) */}
      {list.map(({ passenger, passengerID, name, numberID, expand }, i) => (
        <div key={i} style={{ marginTop: 24 }}>
          <Accordion
            id={`${passenger}-passengerInfo-${i}`}
            expanded={expand}
            onChange={() => handleAccordion(i)}
            className={"dropShadow"}
          >
            <AccordionSummary>
              {(expand && userIndex == i) || name == "" || numberID == "" ? (
                // NOT FILLED PASSENGER DATA
                <div className={"displayFlex"} style={{ width: "100%" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AccountCircleOutlinedIcon
                      style={{ marginRight: 10, color: "#849FBD" }}
                    />
                    <Typography
                      children={
                        passenger === "adult"
                          ? `Dewasa ${i + 1}`
                          : `Bayi ${i + 1 - adultPassengers}`
                      }
                      className={"accordianLabelText"}
                    />
                    <span style={{ color: "red", fontSize: "bold" }}>*</span>
                  </div>
                  {expand && userIndex == i ? (
                    ""
                  ) : (
                    <Typography
                      className={"contactSummaryEditText"}
                      children={"Isi Data"}
                    />
                  )}
                </div>
              ) : (
                // FILLED PASSENGER DATA
                <div className={"displayFlex"} style={{ width: "100%" }}>
                  <div>
                    <Typography
                      className={
                        "summaryFilled-primaryText filledPassenger-primaryText"
                      }
                      children={name}
                    />
                    <Typography
                      className={
                        "summaryFilled-secondaryText filledPassenger-secondaryText"
                      }
                      children={`${passengerID} - ${numberID}`}
                      style={{ marginTop: 2 }}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      className={"contactSummaryEditText"}
                      children={"Ubah"}
                    />
                  </div>
                </div>
              )}
            </AccordionSummary>

            <AccordionDetails style={{ paddingTop: "0" }}>
              <Divider className={classes.customDivider} />
              {/* INPUT PASSENGER NAME */}
              <div style={{ marginTop: 24 }}>
                <Typography
                  className={"formInputTitles"}
                  children={"Nama Lengkap"}
                />

                <TextView
                  variant={"standard"}
                  id={`namaLengkapPassenger-value-${i}`}
                  fullWidth
                  value={passengerName}
                  autoComplete={"off"}
                  onChange={(e) => {
                    if (
                      e.target.value.length <= 25 &&
                      /^[a-zA-Z ]*$/.test(e.target.value)
                    )
                      setPassengerName(e.target.value);
                  }}
                  disabled={i === 0 && disableFirstName} // DISABLE WHEN CHECK ON CHECKBOX AFTER CONTACT FORM
                  error={
                    error && (passengerName == "" || passengerName.length < 3)
                  }
                  helperText={
                    error &&
                    (passengerName == "" || passengerName.length < 3) ? (
                      <span className={"helperText-error"}>
                        <ErrorRoundedIcon style={{ marginRight: 8 }} /> Mohon
                        masukkan nama lengkap, tanpa tanda baca.
                      </span>
                    ) : (
                      ""
                    )
                  }
                />
              </div>

              {/* SELECT PASSENGER ID */}
              <div style={{ marginTop: 24 }}>
                <Typography
                  className={"formInputTitles"}
                  children={"Jenis ID"}
                />

                <div
                  id={`selectPassengerID-${i}`}
                  onClick={() => setOpenUserID(!openUserID)}
                  style={{
                    borderBottom: "1px solid #CFCFCF",
                    padding: "9px 4px",
                  }}
                  className={"displayFlex"}
                >
                  <Typography
                    children={passengerID}
                    style={{ fontSize: 16, fontWeight: 600, color: "#333" }}
                  />
                  <KeyboardArrowDownRoundedIcon
                    className={"iconArrowInSelectInput"}
                  />
                </div>
                <span
                  style={{
                    display: error && passengerID == "" ? "" : "none",
                    fontWeight: "500",
                    color: "#c21616",
                    fontSize: "12px",
                  }}
                >
                  Mohon pilih jenis ID
                </span>
              </div>

              {/* INPUT PASSENGER ID VALUE */}
              <div style={{ marginTop: 24 }}>
                <Typography
                  className={"formInputTitles"}
                  children={"Nomor ID"}
                />

                <TextView
                  variant={"standard"}
                  id={`nomorID-value-${i}`}
                  fullWidth
                  className={classes.telInput}
                  autoComplete={"off"}
                  value={passengerNumberID}
                  type={passengerID == "Paspor" ? "text" : "number"}
                  inputProps={{
                    inputMode: passengerID == "Paspor" ? "" : "numeric",
                    pattern: passengerID == "Paspor" ? "" : "[0-9]*",
                  }}
                  onChange={(e) => {
                    if (e.target.value.length <= 16 && passengerID === "KTP")
                      setPassengerNumberID(e.target.value);
                    if (e.target.value.length <= 8 && passengerID === "Paspor")
                      setPassengerNumberID(e.target.value);
                  }}
                  error={
                    error &&
                    (passengerNumberID === "" ||
                      passengerNumberID < 0 ||
                      (passengerID === "KTP" &&
                        passengerNumberID.length != 16) ||
                      (passengerID === "Paspor" &&
                        passengerNumberID.length != 8))
                  }
                  helperText={
                    (error &&
                      (passengerNumberID === "" ||
                        passengerNumberID < 0 ||
                        (passengerID === "KTP" &&
                          passengerNumberID.length !== 16) ||
                        (passengerID === "Paspor" &&
                          passengerNumberID.length !== 8)) &&
                      (passengerID === "Paspor" ? (
                        <span className={"helperText-error"}>
                          <ErrorRoundedIcon style={{ marginRight: 8 }} /> Nomor
                          ID harus 8 digit.
                        </span>
                      ) : (
                        <span className={"helperText-error"}>
                          <ErrorRoundedIcon style={{ marginRight: 8 }} /> Nomor
                          ID harus 16 digit.
                        </span>
                      ))) ||
                    "Untuk penumpang di bawah 17 tahun, mohon gunakan NIK yang terdapat di Kartu Keluarga"
                  }
                />
              </div>

              {/* SELECT DOB IF CHILD */}
              {passenger !== "adult" && (
                <div style={{ marginTop: 24 }}>
                  <Typography
                    className={"formInputTitles"}
                    children={"Tanggal Lahir"}
                    id="TanggalLahir-label"
                  />
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={indonesian}
                  >
                    <MobileDatePicker
                      disableFuture
                      DialogProps={{ className: classes.selectDepartureDate }}
                      value={childBirthDate}
                      cancelText={""}
                      onChange={(date) => setChildBirthDate(date)}
                      renderInput={(params) => {
                        params.inputProps.value =
                          childBirthDate &&
                          moment(params.inputProps.value, "DD/MM/YYYY").format(
                            "ddd, DD MMM YYYY"
                          );
                        let dateValidation =
                          moment(departdate, "YYYY-MM-DD").diff(
                            moment(childBirthDate).subtract(1, "days"),
                            "years"
                          ) >= 3
                            ? false
                            : true;
                        return (
                          <TextView
                            variant={"standard"}
                            fullWidth
                            className={classes.departureDateTextField}
                            {...params}
                            error={
                              (error && !dateValidation) ||
                              (error && !childBirthDate)
                            }
                            helperText={
                              ((error && !dateValidation) ||
                                (error && !childBirthDate)) && (
                                <span className={"helperText-error"}>
                                  <ErrorRoundedIcon
                                    style={{ marginRight: 8 }}
                                  />{" "}
                                  Umur Bayi Tidak Sesuai
                                </span>
                              )
                            }
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>

                  <div style={{ margin: "16px 0" }}>
                    <Typography
                      className={"childrenNote"}
                      children={"Penumpang bayi tidak mendapat kursi sendiri"}
                    />
                  </div>
                </div>
              )}

              <Button
                id={`simpanPassenger-${i}`}
                fullWidth
                children={"Simpan"}
                variant={"outlined"}
                className={`themeButtonOutline themeButton`}
                style={{ marginTop: 16 }}
                onClick={() => handleSubmitPassengerInfo(passenger)}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      ))}

      {/* CHANGE ID (KTP/Paspor) POPUP */}
      <SelectUserID
        radioButton={radioButton}
        setRadioButton={setRadioButton}
        setOpenUserID={setOpenUserID}
        openUserID={openUserID}
        submitUserID={submitUserID}
      />
    </div>
  );
};

export default PassengerInfo;

const SelectUserID = ({
  radioButton,
  setRadioButton,
  openUserID,
  setOpenUserID,
  submitUserID,
}) => (
  <div
    style={{
      position: "fixed",
      top: "0",
      bottom: "0",
      right: "0",
      left: "0",
      zIndex: "1",
      background: "#00000042",
      display: openUserID ? "" : "none",
      backdropFilter: "blur(2px)",
    }}
  >
    <Slide
      direction={"up"}
      in={openUserID}
      className={""}
      style={{
        position: "absolute",
        bottom: "0",
        right: "0",
        left: "0",
        maxWidth: "640px",
        margin: "auto",
        zIndex: "1",
        borderRadius: "16px 16px 0 0",
      }}
      id="slider-container-ID"
    >
      <Paper id="slider-paper-ID">
        <div style={{ padding: "16px" }}>
          {/* SELECT ID HEADER */}
          <div className={"selectStationSliderHeader"}>
            <Typography
              children={"Jenis ID"}
              color={"#333"}
              className="headerText"
              id="headerTextSlider-ID"
            />
            <IconButton onClick={() => setOpenUserID(!openUserID)}>
              {" "}
              <CloseIcon style={{ color: "#1A1A1A" }} />{" "}
            </IconButton>
          </div>

          {/* RADIO BUTTONS */}
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Radio
                id="KTP"
                sx={{
                  color: "#849FBD",
                  "&.Mui-checked": {
                    color: "#1078CA",
                  },
                }}
                checked={radioButton === "KTP"}
                onChange={(e) => setRadioButton(e.target.value)}
                value="KTP"
              />
              <Typography
                children={"KTP"}
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#333",
                  marginLeft: "5px",
                }}
              />
            </div>

            <Divider />

            <div style={{ display: "flex", alignItems: "center" }}>
              <Radio
                id="Paspor"
                sx={{
                  color: "#849FBD",
                  "&.Mui-checked": {
                    color: "#1078CA",
                  },
                }}
                checked={radioButton === "Paspor"}
                onChange={(e) => setRadioButton(e.target.value)}
                value="Paspor"
              />
              <Typography
                children={"Paspor"}
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#333",
                  marginLeft: "5px",
                }}
              />
            </div>
          </div>

          <Button
            id="ok-button"
            fullWidth
            className={`themeButton themeButtonContained`}
            children={"OK"}
            style={{ marginTop: 12 }}
            variant={"contained"}
            onClick={() => {
              submitUserID(radioButton);
              setOpenUserID(!openUserID);
            }}
          />
        </div>
      </Paper>
    </Slide>
  </div>
);

const useStyle = makeStyles({
  telInput: {
    "& .MuiInputBase-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
  },

  selectDepartureDate: {
    // Hide Cancel Button
    "& .MuiDialogActions-root>:first-of-type": {
      display: "none",
    },
    // giv a line after the date displayed at top in Date Picker
    "& .PrivatePickersToolbar-root": {
      borderBottom: "1px solid #DDD !important",
    },
    // removed the edit button which changed the view of the date picker
    "& .MuiPickersToolbar-penIconButton": {
      display: "none",
    },
    // change color and background of the CANCEL and OK button
    "& .MuiButton-root": {
      background: "#1078CA",
      color: "#FFF",
      "&:hover": {
        background: "#00529C",
      },
    },
    // background color applied to date when selected and also when seledted and hover on it
    "& .Mui-selected": {
      backgroundColor: "#1F8FE5 !important",
      "&:hover": {
        backgroundColor: "#00529C !important",
      },
    },
    "& .MuiButtonBase-root": {
      "&:hover": {
        backgroundColor: "#EEF2F6",
      },
    },
  },
  departureDateTextField: {
    // style APPLIED to TEXTFIELD
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px !important",
      fontSize: "14px !important",
    },
  },

  // CUSTOM DIVIDER STYLE
  customDivider: {
    borderStyle: "dotted",
    borderBottomWidth: "2px",
  },
});
