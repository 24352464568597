import styled from "@emotion/styled";
import { TextField } from "@mui/material";

const TextView = styled(TextField)(() => ({
  // STYLES APPLYED TO INPUTBASE WHICH IS INSIDE TEXTFIELD
  // VARIANT "FILLED"
  "& .MuiFilledInput-root": {
    border: "none !important",
    background: "#EEF2F6 !important",
    borderRadius: 8,
    "&:before": {
      border: "none !important",
    },
    "&:hover:before": {
      border: "none !important",
    },
    "&:after": {
      border: "none !important",
    },
    "& input": {
      padding: "9px 4px",
      fontWeight: 500,
      fontSize: 16,
      color: "#333",
    },
    "& input::placeholder": {
      color: "#004c97 !important",
    },
    "& .MuiInputAdornment-root": {
      marginTop: "0 !important",
    },
  },
  // VARIANT "OUTLINED"
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& input": {
      padding: "9px 4px",
      fontWeight: 600,
      fontSize: 16,
    },
  },
  // VARIANT "STANDARD"
  "& .MuiInput-root": {
    borderColor: "#CFCFCF",
    "&:before": {
      borderColor: "#CFCFCF",
    },
    "&:hover:before": {
      borderBottom: "2px solid #00529C !important",
    },
    "& input": {
      padding: "9px 4px",
      fontWeight: 600,
      fontSize: 16,
      color: "#333",
    },
  },
  // HELPER TEXT STYLE
  "& .MuiFormHelperText-root": {
    color: "#849FBD",
    fontSize: 12,
    fontWeight: 500,
    marginTop: 8,
  },
  // ON ERROR THE VALUE & HELPER TEXT BELOW ALL WILL GET COLOR #F75656
  "& .Mui-error": {
    color: "#F75656 !important",
    // APPLY SAME COLOR TO BORDER
    "&:after": {
      borderColor: "#F75656 !important",
    },
    "& input": {
      color: "#F75656",
    },
  },
}));

export default TextView;
