import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Paper, Typography } from "@mui/material";
import { Loader } from "../index";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";

const baseURL = process.env.REACT_APP_BASEURL;
const SubmitedPassengerInfo = ({ passengerInfoList }) => {
  const navigate = useNavigate();
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [passengerInfo, setPassengerInfo] = useState({});

  useEffect(() => {
    if (passengerInfoList?.paxes?.length) {
      setList([...passengerInfoList.paxes]);
      setPassengerInfo(passengerInfoList);
    }
  }, [passengerInfoList]);

  const handleChangeSeat = (passengerSeatInfo) => {
    setLoading(true);
    let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));

    const BASE_URL = baseURL;
    let URL = BASE_URL + "/kai/train/seat/map";

    let body = {
      enrollmentId: enrollmentId,
      tripId: passengerInfo.tripId,
      wagonClassId: passengerInfo.wagonClassId,
      subClass: passengerInfo.subClass,
      originId: passengerInfo.originId,
      destinationId: passengerInfo.destinationid,
    };
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Clientid: "Kai-Enrollment",
        // "Authorization": "Bearer " + token
      },
    };

    axios
      .post(URL, body, headers)
      .then((res) => {
        setLoading(false);
        if (res.data?.success) {
          const data = res.data;

          if (data.classSeatData.length) {
            const list = data.classSeatData;
            const currentSeatClass = passengerSeatInfo.stamformdetcode;
            const currentSeat =
              passengerSeatInfo.wagondetrow + passengerSeatInfo.wagondetcol;

            for (let i = 0; i < list.length; i++) {
              let classname = Object.keys(list[i])[0];
              if (classname === currentSeatClass) {
                list[i][classname].classSeatAvailable.push(currentSeat);
              }
            }
          }

          let KAI = JSON.parse(localStorage.getItem("KAI"));
          KAI.seatList = {
            currentSeat: passengerSeatInfo,
            list: data,
            paxes: KAI.ticketBookingResponse.data.paxes,
          };
          localStorage.setItem("KAI", JSON.stringify(KAI));
          navigate("/pilihkursi");
        } else
          showErrorPopUp({
            ...errorPopUp,
            showPopUp: true,
            message: res.message,
          });
      })
      .catch((error) => {
        setLoading(false);
        showErrorPopUp({
          ...errorPopUp,
          showPopUp: true,
          message: error.message,
        });
        // console.error(error)
      });
  };

  const handlePopUpClose = () => {
    showErrorPopUp({ ...errorPopUp, showPopUp: false });
  };

  return (
    <div>
      <Loader loading={loading} />
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <div style={{ marginTop: "24px" }}>
        <Typography
          children={"Data Penumpang"}
          className={"inputSectionLabels"}
        />
      </div>

      {list.map(
        (
          {
            name,
            ticketnum,
            wagondetrow,
            wagondetcol,
            stamformdetcode,
            passengertype,
            id,
          },
          i
        ) => (
          <div key={i} style={{ marginTop: "16px" }}>
            <Paper
              style={{
                padding: "12px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className={"dropShadow"}
            >
              <div>
                <Typography
                  id="passengerSubmited-name"
                  className={"submitedPassengerName"}
                  children={name}
                />
                {/* <Typography id="passengerSubmited-passengerID-numberID" className={'submitedPassengerID'} children={`Kode Tiket - ${ticketnum}`} /> */}
                <Typography
                  id="passengerSubmited-seat"
                  className={"submitedPassengerID"}
                  children={
                    passengertype !== "A"
                      ? "Bayi"
                      : `${wagondetrow}${wagondetcol} / ${stamformdetcode}`
                  }
                />
              </div>
              <div style={{ display: passengertype !== "A" ? "none" : "" }}>
                <Button
                  id="passenger-GantiKursi"
                  children={"Ganti Kursi"}
                  variant={"contained"}
                  //  endIcon={<ArrowForwardIosRoundedIcon style={{fontSize:"12px"}} />}
                  style={{
                    padding: "6px 16px",
                    background: "#1078CA",
                    fontSize: 12,
                  }}
                  className={"themeButton"}
                  onClick={() => handleChangeSeat(list[i])}
                />
              </div>
            </Paper>
          </div>
        )
      )}
    </div>
  );
};

export default SubmitedPassengerInfo;
