import styled from "@emotion/styled";
import { Select } from "@mui/material";

const SelectView = styled(Select)(() => ({
  // VARIANT "OUTLINED"
  "& .MuiSelect-outlined": {
    padding: "10px 14px",
    color: "#333",
    fontSize: 16,
    fontWeight: 600,
    "& .MuiSelect-select": {
      borderRadius: "10px !important",
    },
  },
  // VARIANT STANDARD
  "&.MuiInput-root": {
    paddingLeft: 4,
    color: "#333",
    fontSize: 16,
    fontWeight: 600,
    "&::before": {
      borderColor: "#CFCFCF !important",
    },
    "& .MuiSelect-standard:focus": {
      backgroundColor: "transparent !important",
    },
    "&.Mui-error": {
      // borderColor: 'green !important',
      "&.MuiInput-root": {
        color: "#F75656 !important",
      },
      "&::after": {
        borderColor: "#F75656 !important",
      },
    },
  },
}));

export default SelectView;
