import { getWagonClass } from "../../../common/Utils";

const changePage = (action, selectedClass, setSelectedClass) => {
  const KAI = JSON.parse(localStorage.getItem("KAI"));
  const noOfPages = KAI.seatList?.list?.classSeatData?.length;
  const seatClasses = KAI.seatList?.list?.classSeatData;
  let currentPageNo = parseInt(selectedClass.split(" ")[1][1]);
  let index = 0;
  if (action === "next") {
    if (currentPageNo === noOfPages) {
      currentPageNo = 1;
      index = 0;
    } else {
      currentPageNo++;
      index = currentPageNo - 1;
    }
  }
  if (action === "prev") {
    if (currentPageNo === 1) {
      currentPageNo = noOfPages;
      index = currentPageNo - 1;
    } else {
      currentPageNo--;
      index = currentPageNo - 1;
    }
  }
  const seatClass = Object.keys(seatClasses[index])[0].split("-")[0];
  setSelectedClass(getWagonClass(seatClass, index + 1));
};

export default changePage;
