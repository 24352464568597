import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import SelectWagon from "./SelectWagon/SelectWagon";
import SeatLayout from "./SeatLayout/SeatLayout";
import { getWagonClass } from "../../common/Utils";

const SelectSeatContainer = ({ setSeatChange }) => {
  const [selectedClass, setSelectedClass] = useState("");
  const [currentSelectedClassLayout, setCurrentSelectedClassLayout] =
    useState("");

  useEffect(() => {
    const KAI = JSON.parse(localStorage.getItem("KAI"));
    const seatList = KAI.seatList;

    // SET THE DEFAULT CLASS OF THE CURRENT PASSENGES EX =>
    // if we have 3 wagon (EKS-1,EKS-2,EKS-3) & default wagon of passenger is EKS-2 open to wagon page 2
    seatList.list.classSeatData.map((e) => {
      if (Object.keys(e)[0] === seatList.currentSeat.stamformdetcode) {
        const wagonClassName = Object.keys(e)[0].split("-")[0];
        const wagonClassNumber = Object.keys(e)[0].split("-")[1];

        const defaultWagonClass = getWagonClass(
          wagonClassName,
          wagonClassNumber
        );

        setSelectedClass(defaultWagonClass);
      }
      return 0;
    });
  }, []);

  // AFTER SETTING DEFAULT WAGON GET => THE LAYOUT INFO OF DEFAULT CLASS
  // GET THE LAYOUT INFO ON CHANGE OF EACH WAGON CLASS...
  useEffect(() => {
    if (selectedClass) {
      const KAI = JSON.parse(localStorage.getItem("KAI"));

      const classSeatDataList = [...KAI.seatList.list.classSeatData];
      classSeatDataList.forEach((obj) => {
        const key = Object.keys(obj)[0];
        let classCurrentLayout = "";
        const currentSelectedClass = getWagonClass(
          key.split("-")[0],
          key.split("-")[1]
        );

        if (currentSelectedClass === selectedClass) {
          classCurrentLayout = obj[key];
          setCurrentSelectedClassLayout(classCurrentLayout);
        }
      });
    }
  }, [selectedClass]);

  return (
    <div>
      <SeatIndicators />

      <SelectWagon
        selectedClass={selectedClass}
        setSelectedClass={setSelectedClass}
      />

      <SeatLayout
        selectedClass={selectedClass}
        setSelectedClass={setSelectedClass}
        setSeatChange={setSeatChange}
        currentSelectedClassLayout={currentSelectedClassLayout}
      />
    </div>
  );
};

const SeatIndicators = () => {
  return (
    <div className={"displayFlex"} style={{ padding: "16px 16px 0px 16px" }}>
      <div className={"displayFlex"}>
        <div className={"seatIndicators"} style={{ background: "#1078CA" }} />
        <Typography
          style={{ fontSize: 12, fontWeight: 500, color: "#333" }}
          children={"Aktif"}
        />
      </div>
      <div className={"displayFlex"}>
        <div
          className={"seatIndicators"}
          style={{ border: "1px solid #1078CA" }}
        />
        <Typography
          style={{ fontSize: 12, fontWeight: 500, color: "#333" }}
          children={"Dipilih"}
        />
      </div>
      <div className={"displayFlex"}>
        <div className={"seatIndicators"} style={{ background: "#B7B7B7" }} />
        <Typography
          style={{ fontSize: 12, fontWeight: 500, color: "#333" }}
          children={"Terisi"}
        />
      </div>
    </div>
  );
};

export default SelectSeatContainer;
