import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import { TextView } from "../../common/Components";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";

const ContactDetails = ({ submitError, setDisableFirstName }) => {
  const classes = useStyle();
  const [openContactDetails, setOpenContactDetails] = useState(false);
  const [name, setName] = useState("");
  const [phoneCode, setPhoneCode] = useState("62");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  /* eslint-disable-next-line */
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [checkboxDisable, setCheckboxDisable] = useState(true);
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    let KAI = JSON.parse(localStorage.getItem("KAI"));

    KAI.customerContactDetails = {};
    if (name !== "") KAI.customerContactDetails.name = name;
    else KAI.customerContactDetails.name = "";

    if (phoneCode !== "") KAI.customerContactDetails.phoneCode = phoneCode;
    else KAI.customerContactDetails.phoneCode = "";

    if (phoneNo !== "" && phoneNo.length >= 10 && phoneNo > 0)
      KAI.customerContactDetails.phoneNo = phoneNo;
    else KAI.customerContactDetails.phoneNo = "";

    if (email !== "" && emailRegex.test(email))
      KAI.customerContactDetails.email = email;
    else KAI.customerContactDetails.email = "";

    localStorage.setItem("KAI", JSON.stringify(KAI));

    if (
      name !== "" &&
      phoneCode !== "" &&
      phoneCode > 0 &&
      phoneNo !== "" &&
      phoneNo.length >= 10 &&
      phoneNo > 0 &&
      email !== "" &&
      emailRegex.test(email)
    ) {
      setOpenContactDetails(false);
      setCheckboxDisable(false);
    } else {
      setError(true);
      setCheckboxDisable(true);
    }
  };

  useEffect(() => {
    if (submitError) {
      if (
        name === "" ||
        phoneCode === "" ||
        phoneNo.length <= 10 ||
        phoneNo < 0 ||
        phoneNo === "" ||
        email === "" ||
        !emailRegex.test(email)
      ) {
        setError(true);
        setOpenContactDetails(true);
      }
    }
  }, [submitError, email, emailRegex, name, phoneCode, phoneNo]);

  useEffect(() => {
    const p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));

    p.then((res) => {
      if (res?.customerContactDetails) {
        setName(res.customerContactDetails.name);
        setPhoneCode(res.customerContactDetails.phoneCode);
        setPhoneNo(res.customerContactDetails.phoneNo);
        setEmail(res.customerContactDetails.email);
      }
    });
  }, []);

  const setContactPersonAsUser = (isContactPersonAsUser) => {
    const KAI = JSON.parse(localStorage.getItem("KAI"));

    KAI.passengerList[0].name = name;
    setDisableFirstName(isContactPersonAsUser);
    localStorage.setItem("KAI", JSON.stringify(KAI));
  };

  return (
    <div id={"contact-details"} style={{ marginTop: "24px" }}>
      <Typography
        children={"Data Pemesan (Untuk E - Ticket)"}
        className={"inputSectionLabels"}
      />

      {/* FILL THE CONTACT DETAILS */}
      <Accordion
        expanded={openContactDetails}
        onChange={() => setOpenContactDetails(!openContactDetails)}
        id={"dataPemesan-dropdown"}
        style={{ marginTop: "16px" }}
        className={"dropShadow"}
      >
        <AccordionSummary>
          {name === "" ||
          phoneCode === "" ||
          phoneNo === "" ||
          email === "" ||
          openContactDetails ? (
            // WHEN CONTACT DETAILS NOT FILLED
            <div className={"displayFlex"} style={{ width: "100%" }}>
              <Typography className={"accordianLabelText"}>
                Isi Data Pemesan
                <span style={{ color: "red", fontSize: "bold" }}>*</span>
              </Typography>
              <Typography
                className={"contactSummaryEditText"}
                children={"Isi Data"}
                style={{ display: openContactDetails ? "none" : "" }}
              />
            </div>
          ) : (
            // WHEN CONTACT DETAILS FILLED
            <div className={"displayFlex"} style={{ width: "100%" }}>
              <div>
                <Typography
                  className={`summaryFilled-primaryText filledContact-primaryText`}
                  children={name}
                />
                <Typography
                  className={`summaryFilled-secondaryText filledContact-secondaryText`}
                  children={email}
                  style={{ marginTop: 2 }}
                />
                <Typography
                  className={`summaryFilled-secondaryText filledContact-secondaryText`}
                  children={`+${phoneCode} ${phoneNo}`}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  className={"contactSummaryEditText"}
                  children={"Ubah"}
                />
              </div>
            </div>
          )}
        </AccordionSummary>

        <AccordionDetails style={{ paddingTop: "0" }}>
          <Divider
            style={{ marginBottom: "16px" }}
            className={classes.customDivider}
          />
          <Typography children={"Nama Lengkap"} className={"formInputTitles"} />

          {/* CONTACT PERSON NAME */}
          <TextView
            variant={"standard"}
            id={"namaLengkap-value"}
            fullWidth
            autoComplete={"off"}
            value={name}
            error={error && name === ""}
            onChange={(e) => {
              if (
                e.target.value.length <= 25 &&
                /^[a-zA-Z ]*$/.test(e.target.value)
              )
                setName(e.target.value);
            }}
            FormHelperTextProps={{ style: { marginBottom: 24 } }}
            helperText={
              error && name === "" ? (
                <span className={"helperText-error"}>
                  <ErrorRoundedIcon style={{ marginRight: 8 }} /> Mohon untuk
                  tidak memasukkan tanda baca
                </span>
              ) : (
                "Sesuai KTP/paspor/SIM (tanpa tanda baca atau gelar)"
              )
            }
          />

          {/* PHONE INFO */}
          <Grid container spacing={1} style={{ marginBottom: 24 }}>
            {/* PHONE CODE */}
            <Grid item xs={4}>
              <Typography
                children={"Kode Negara"}
                className={"formInputTitles"}
              />

              <TextView
                variant={"standard"}
                id={"kodeNegara-value"}
                fullWidth
                autoComplete={"off"}
                className={classes.telInput}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                type={"number"}
                InputProps={{
                  startAdornment: <AddIcon fontSize={"small"} />,
                }}
                error={error && (phoneCode === "" || phoneCode < 0)}
                helperText={
                  (phoneCode === "" || phoneCode < 0) &&
                  error && (
                    <span className={"helperText-error"}>
                      {" "}
                      <ErrorRoundedIcon style={{ marginRight: 8 }} /> Pilih Kode
                      Negara
                    </span>
                  )
                }
                value={phoneCode}
                onChange={(e) => {
                  if (e.target.value.length <= 5) setPhoneCode(e.target.value);
                }}
              />
            </Grid>
            {/* PHONE NUMBER */}
            <Grid item xs={8}>
              <Typography
                children={"No. Handphone"}
                className={"formInputTitles"}
              />

              <TextView
                variant={"standard"}
                autoComplete={"off"}
                fullWidth
                className={classes.telInput}
                id={"handphone-value"}
                error={
                  error && (phoneNo < 0 || phoneNo === "" || phoneNo.length < 10)
                  // (phoneNo.length!=10 && error)||
                }
                value={phoneNo}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                type={"number"}
                onChange={(e) => {
                  if (e.target.value.length <= 15) setPhoneNo(e.target.value);
                }}
                helperText={
                  (phoneNo === "" ||
                    phoneNo < 0 ||
                    phoneNo.length < 10) /*||(error && phoneNo.length!=10)*/ &&
                  error && (
                    <span className={"helperText-error"}>
                      <ErrorRoundedIcon style={{ marginRight: 8 }} /> No.
                      Handphone tidak terdaftar
                    </span>
                  )
                }
              />
            </Grid>
          </Grid>

          <Typography children={"Email"} className={"formInputTitles"} />
          <TextView
            variant={"standard"}
            autoComplete={"off"}
            fullWidth
            id={"email"}
            error={(error && email === "") || (error && !emailRegex.test(email))}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            FormHelperTextProps={{ style: { marginBottom: 8 } }}
            helperText={
              (email === "" && error) || (error && !emailRegex.test(email)) ? (
                <span className={"helperText-error"}>
                  {" "}
                  <ErrorRoundedIcon style={{ marginRight: 8 }} /> Email tidak
                  terdaftar
                </span>
              ) : (
                "Contoh: email@example.com"
              )
            }
          />

          <Button
            id={'submitContactInfo'}
            children={"Simpan"}
            className={`themeButtonOutline themeButton`}
            variant={"outlined"}
            style={{ marginTop: 16 }}
            fullWidth
            onClick={() => handleSubmit()}
          />
        </AccordionDetails>
      </Accordion>

      <FormControlLabel
        id={'addSameNameToDetails'}
        style={{ marginTop: 7 }}
        className={classes.checkboxLabel}
        disabled={checkboxDisable}
        label={"Sama dengan Data Pemesan"}
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlankRoundedIcon />}
            checkedIcon={<CheckBoxRoundedIcon style={{ color: "#1078CA" }} />}
          />
        }
        onChange={(e) => setContactPersonAsUser(e.target.checked)}
      />
    </div>
  );
};

export default ContactDetails;

const useStyle = makeStyles({
  // REMOVES THE ARROW ICONS FROM THE INPUT FOR NUMERIC TYPE
  telInput: {
    "& .MuiInputBase-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
  },
  customDivider: {
    borderStyle: "dotted",
    borderBottomWidth: "2px",
  },
  checkboxLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      fontWeight: 500,
      color: "#888",
    },
  },
});
